import styled from "styled-components";

const container = styled.div<{ width?: number }>`
  position: relative;
  width: ${({ width }) => (width ? `${width}px` : "100%")};
`;

export default {
  container,
};
