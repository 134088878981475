// 상위 20개의 POL, POD ID를 추천 리스트로 사용

const POL_CODE_RECOMMENDATION_LIST_FOR_SEA = [
  "CNWEI",
  "CNSHG",
  "CNNGB",
  "CNSNZ",
  "CNQDG",
  "CNXMG",
  "CNTXG",
  "CNCAN",
  "CNSHK",
  "VNSGN",
  "CNSTG",
  "DEHAM",
  "IDJKT",
  "CNXGA",
  "VNHPH",
  "IDSUB",
  "HKHKG",
  "JPOSA",
  "USLAX",
  "THBKK",
];

const POL_CODE_RECOMMENDATION_LIST_FOR_AIR = [
  "MXP",
  "FRA",
  "PVG",
  "CDG",
  "LAX",
  "LHR",
  "CAN",
  "SGN",
  "SZX",
  "JFK",
  "BCN",
  "TPE",
  "NRT",
  "SIN",
  "AMS",
  "IST",
  "BKK",
  "KIX",
  "YIW",
  "ARN",
];

const POD_CODE_RECOMMENDATION_LIST_FOR_SEA = [
  "KRINC",
  "KRPUS",
  "KRPTK",
  "KRKWA",
  "KRKUV",
  "KRUSN",
  "KRBNP",
];

const POD_CODE_RECOMMENDATION_LIST_FOR_AIR = ["ICN"];

export {
  POL_CODE_RECOMMENDATION_LIST_FOR_SEA,
  POL_CODE_RECOMMENDATION_LIST_FOR_AIR,
  POD_CODE_RECOMMENDATION_LIST_FOR_SEA,
  POD_CODE_RECOMMENDATION_LIST_FOR_AIR,
};
