import { RefObject, useMemo } from "react";

import COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/COMMON_QUERY";
import InputSearchWithOptionsV2, {
  InputSearchWithOptionsHandlerList,
} from "@sellernote/_sds-v2/src/components/form/InputSearchWithOptionsV2";

import { getOptionList, getPortType, getRecommendationList } from "../utils";

import { useSearch } from "../SearchContext";

export default function Pol({
  polOptionHandlerListRef,
  isMobile = false,
}: {
  polOptionHandlerListRef: RefObject<InputSearchWithOptionsHandlerList>;
  isMobile?: boolean;
}) {
  const [{ freightType, polId }, setSearch] = useSearch();

  const { data: polList } = COMMON_QUERY.useGetUserPortList({
    portType: getPortType(freightType),
    exceptCountry: ["KR"],
  });

  const polOptionList = useMemo(() => getOptionList(polList), [polList]);

  const polRecommendationList = useMemo(
    () =>
      getRecommendationList({
        locationType: "pol",
        freightType,
        list: polList,
      }),
    [freightType, polList]
  );

  const handlePolSelect = (selectedPolId: number | null) => {
    setSearch({ polId: selectedPolId });
  };

  const handlePolReset = () => {
    setSearch({ polId: null });
  };

  return (
    <InputSearchWithOptionsV2<number | null>
      className="custom-label-color"
      {...(!isMobile && { width: 296 })}
      labelInfo={{
        label: (
          <>
            출발지<span className="required">*</span>
          </>
        ),
        position: "top",
      }}
      placeholder="입력해주세요."
      searchSourceList={polOptionList}
      selectedOptionValue={polId}
      onSelect={handlePolSelect}
      onReset={handlePolReset}
      recommendedList={polRecommendationList}
      inputSearchWithOptionsHandlerListRef={polOptionHandlerListRef}
    />
  );
}
