import {
  RefObject,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

import useClickOutsideOfElement from "@sellernote/_shared/src/hooks/common/useClickOutsideOfElement";
import { useDebounce } from "@sellernote/_shared/src/utils/common/hook";
import { removeLeadingBlank } from "@sellernote/_shared/src/utils/common/string";

import InputSearch, { InputSearchLabelInfo } from "../InputSearch";
import SearchResultOptions from "./SearchResultOptions";
import Styled from "./index.styles";

export interface InputSearchOption<T> {
  label: string;
  value: T;
  disabled?: boolean;
}

export type InputSearchWithOptionsHandlerList = {
  focusInput: () => void;
};

interface InputSearchWithOptionsProps<T> {
  searchSourceList?: InputSearchOption<T>[];
  searchSourceListWidth?: string | number;
  selectedOptionValue: T;
  onSelect?: (value: T) => void;
  onReset: () => void;
  /** labelInfo.position이 left 인 경우 min-width를 필수로 넣어야합니다. */
  labelInfo?: InputSearchLabelInfo;
  placeholder?: React.ReactNode;
  width?: number;
  disabled?: boolean;
  errorMessage?: React.ReactNode;
  /**
   * 추천 목록 (포커싱 + input에 입력한 값이 없을 때 보여짐)
   */
  recommendedList?: InputSearchOption<T>[];
  inputSearchWithOptionsHandlerListRef?: RefObject<InputSearchWithOptionsHandlerList>;
  className?: string;
}

/**
 * V1과의 차이점
 * - inputRef, inputValue를 내부에서 관리
 * - 외부 요인으로 인해 selectedOptionValue가 변경되는 경우 화면에 표시하는 값을 동기화시키기 위해 selectedOptionValue가 있을 경우에는 inputValue를 무시하고 selectedOptionValue를 표시
 *   - 위 변경으로 인해 handleSearchResultSelect에서 inputValue를 초기화하지 않음
 */
export default function InputSearchWithOptionsV2<T>({
  searchSourceList = [],
  searchSourceListWidth,
  selectedOptionValue,
  onSelect,
  onReset,
  labelInfo,
  placeholder,
  width,
  disabled,
  errorMessage,
  recommendedList,
  inputSearchWithOptionsHandlerListRef,
  className,
}: InputSearchWithOptionsProps<T>) {
  const [inputValue, setInputValue] = useState("");
  const [opensOptionList, setOpensOptionList] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const searchOptionListWidth = useMemo(() => {
    if (searchSourceListWidth) {
      return searchSourceListWidth;
    }

    if (labelInfo?.position === "left" && labelInfo?.minWidth) {
      return `calc(100% - ${labelInfo.minWidth})`;
    }

    return "100%";
  }, [searchSourceListWidth, labelInfo]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // 선택한 옵션이 있을 경우에는 리셋버튼으로만 수정 가능.
    if (selectedOptionValue) return;

    const value = removeLeadingBlank(e.target.value);

    if (value) {
      setOpensOptionList(true);
    } else {
      // 추천 목록이 있는 경우에는 추천 목록을 표시해야 하므로 옵션 리스트를 열어둔다.
      if (!recommendedList) {
        setOpensOptionList(false);
      }
    }

    setInputValue(value);
  };

  const debouncedSearchTerm = useDebounce(inputValue, 500);

  const { targetElementRef: selectOptionElementRef } = useClickOutsideOfElement(
    {
      onClickOutside: () => {
        setOpensOptionList(false);
      },
    }
  );

  const handleReset = () => {
    onReset();

    setInputValue("");
  };

  const handleSelect = (value: T) => {
    onSelect?.(value);

    setOpensOptionList(false);
  };

  const handleFocus = () => {
    if (recommendedList) {
      inputRef?.current?.focus();
      setOpensOptionList(true);
    }
  };

  useImperativeHandle(inputSearchWithOptionsHandlerListRef, () => ({
    focusInput: handleFocus,
  }));

  const inputValueOfSelectedOptionValue = useMemo(
    () =>
      searchSourceList.find((option) => option.value === selectedOptionValue)
        ?.label,
    [searchSourceList, selectedOptionValue]
  );

  const inputValueToDisplay = selectedOptionValue
    ? inputValueOfSelectedOptionValue
    : inputValue;

  return (
    <Styled.container
      width={width}
      ref={selectOptionElementRef}
      className={`${className ? className : ""} input-search-with-options`}
    >
      <InputSearch
        inputRef={inputRef}
        labelInfo={labelInfo}
        inputValue={inputValueToDisplay || ""}
        onInputValueChange={handleChange}
        onReset={handleReset}
        isFocused={opensOptionList}
        onFocus={handleFocus}
        placeholder={placeholder}
        disabled={disabled}
        errorMessage={errorMessage}
      />

      {opensOptionList && !selectedOptionValue && (
        <SearchResultOptions
          width={searchOptionListWidth}
          searchSourceList={searchSourceList}
          labelInfo={labelInfo}
          searchTerm={debouncedSearchTerm}
          onSelect={handleSelect}
          recommendedList={recommendedList}
        />
      )}
    </Styled.container>
  );
}
