import { GET_USER_PORT_LIST_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/common";
import { FreightTypeOption } from "@sellernote/_shared/src/types/forwarding/schedule";

import {
  POD_CODE_RECOMMENDATION_LIST_FOR_AIR,
  POD_CODE_RECOMMENDATION_LIST_FOR_SEA,
  POL_CODE_RECOMMENDATION_LIST_FOR_AIR,
  POL_CODE_RECOMMENDATION_LIST_FOR_SEA,
} from "./data";

type LocationType = "pol" | "pod";

type RecommendationListType = "polSea" | "polAir" | "podSea" | "podAir";

const getOptionList = (list: GET_USER_PORT_LIST_RES | undefined) =>
  (list ?? []).map(({ name, nameC, nameEN, id }) => ({
    label: formatPort({ name, nameC, nameEN }),
    value: id,
  }));

const getRecommendationListType = (
  locationType: LocationType,
  isSea: boolean
): RecommendationListType => `${locationType}${isSea ? "Sea" : "Air"}`;

const getRecommendationList = ({
  locationType,
  freightType,
  list,
}: {
  locationType: LocationType;
  freightType: FreightTypeOption;
  list: GET_USER_PORT_LIST_RES | undefined;
}) =>
  ({
    polSea: POL_CODE_RECOMMENDATION_LIST_FOR_SEA,
    polAir: POL_CODE_RECOMMENDATION_LIST_FOR_AIR,
    podSea: POD_CODE_RECOMMENDATION_LIST_FOR_SEA,
    podAir: POD_CODE_RECOMMENDATION_LIST_FOR_AIR,
  }[
    getRecommendationListType(locationType, checkIsSeaFreightType(freightType))
  ].map((code) => {
    const port = list?.find((port) => port.code === code);

    if (!port) {
      return { label: "", value: 0 };
    }

    return {
      label: formatPort({
        name: port.name,
        nameC: port.nameC,
        nameEN: port.nameEN,
      }),
      value: port.id,
    };
  }));

const formatPort = ({
  name,
  nameC,
  nameEN,
}: {
  name: string | undefined;
  nameC: string | null | undefined;
  nameEN: string;
}) => {
  if (name) {
    if (nameC) {
      return `${name}(${nameC}) - ${nameEN}`;
    }

    return `${name} - ${nameEN}`;
  }

  // 한글명 없이 별칭만 있는 경우는 없음
  return nameEN;
};

/**
 * freightType은 default를 FCL로 설정했기 때문에, AIR, FCL, LCL 이외의 값이 들어올 수 없음
 */
const getPortType = (freightType: FreightTypeOption) =>
  freightType === FreightTypeOption.Air ? "air" : "sea";

const checkIsSeaFreightType = (freightType: FreightTypeOption) =>
  freightType === FreightTypeOption.FCL ||
  freightType === FreightTypeOption.LCL;

const checkIsAirFreightType = (freightType: FreightTypeOption) =>
  freightType === FreightTypeOption.Air;

export {
  getOptionList,
  getRecommendationListType,
  getRecommendationList,
  formatPort,
  getPortType,
  checkIsSeaFreightType,
  checkIsAirFreightType,
};
